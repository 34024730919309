<template>
  <el-row>
    <el-col :span="24" style="text-align: center">
      <h2>数转智改应用场景信息采集表单</h2>
      <span class="title-small">本表单面向各类数字化转型服务商，征集优质数字化转型产品及应用场景案例。为使产品及应用场景得到更为广泛、精准的推广，望各企业认真填写相关信息。</span>
    </el-col>
    <el-col :span="24" style="text-align: center">
      <div class="title-small">（备注：企业也可通过链接下载附件进行填写，提交至邮箱wfangsz2022@126.com）</div>
      <a class="title-small" style="color: blue;"
        href="https://bd.sdxhsn.com/%E6%95%B0%E8%BD%AC%E6%99%BA%E6%94%B9%E5%BA%94%E7%94%A8%E5%9C%BA%E6%99%AF%E4%BF%A1%E6%81%AF%E9%87%87%E9%9B%86%E8%A1%A8.docx">下载附件：《数转智改应用场景信息采集表》</a>
    </el-col>

    <el-form ref="ruleform" :label-position="labelPosition" label-width="140px" :model="formLabelAlign" size="mini"
      :rules="rules">
      <el-col :span="5">
        <el-form-item></el-form-item>
      </el-col>
      <el-col :span="isMobile == false ? 14 : 24">
        <!--企业基本信息 start-->
        <!--        <el-divider content-position="left"-->
        <!--          ><h3 style="font-size: 16px">企业基本信息</h3></el-divider-->
        <!--        >-->
        <el-card class="box-card" shadow="never" v-if="stepNumber == 1">
          <div slot="header" class="clearfix">
            <span class="tip-tap">
              <h3>企业基本信息</h3>
            </span>
          </div>
          <el-row :gutter="20">
            <el-col :span="0"><el-form-item></el-form-item></el-col>
            <el-col :span="24">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="服务商名称" prop="companyName">
                    <el-input v-model="formLabelAlign.companyName" style="width: 100%"
                      placeholder="请输入服务商名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="地址" prop="companyAddress">
                    <el-input v-model="formLabelAlign.companyAddress" style="width: 100%" placeholder="请输入详细地址"
                      type="textarea" rows="3"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="填报所属区县" prop="affiliation">
                    <el-cascader v-model="affiliationAdress" :options="weifangData" @change="affiliationChooseAdd"
                      :props="{ expandTrigger: 'hover' }" style="width: 100%"></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="企业联系人" prop="contactName">
                    <el-input v-model="formLabelAlign.contactName" style="width: 100%" placeholder="请输入联系人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="职务" prop="duties">
                    <el-input v-model="formLabelAlign.duties" style="width: 100%" placeholder="请输入职务"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="联系人方式" prop="contactTel">
                    <el-input v-model="formLabelAlign.contactTel" placeholder="请输入联系人方式" style="width: 100%"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="邮箱" prop="email">
                    <el-input v-model="formLabelAlign.email" style="width: 100%" placeholder="请输入邮箱"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="服务行业" prop="industry">
                    <el-select v-model="industryList" multiple filterable allow-create default-first-option
                      placeholder="请选择服务行业" @change="selectIndustry" style="width: 100%;">
                      <el-option v-for="item in serviceTradeList" :key="item.dictValue" :label="item.dictLabel"
                        :value="item.dictLabel">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <!-- <el-col :span="24">
                  <el-form-item label="统一社会信用代码" prop="licenceNum">
                    <el-input v-model="formLabelAlign.licenceNum" style="width: 100%"
                      placeholder="请输入统一社会信用代码"></el-input>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :span="24">
                  <el-form-item label="地址" prop="province">
                    <el-cascader style="width: 100%" filterable clearable :options="optionsCitys"
                      v-model="selectedOptions" @change="addressChoose"></el-cascader>
                  </el-form-item>
                </el-col> -->
                <el-col :span="24">
                  <el-form-item label="服务商简介" prop="companyInfo">
                    <el-input v-model="formLabelAlign.companyInfo" style="width: 100%" placeholder="请输入服务商简介"
                      type="textarea" rows="3"></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item label="上传营业执照" prop="licenceUrl">
                    <el-upload v-model="formLabelAlign.licenceUrl" :action="headerUrl + '/common/upload'"
                      list-type="picture-card" :limit="2" :file-list="businessLicenseList"
                      :before-upload="handlePictureCardPreview" :on-success="businessLicenseUpdateSuccess"
                      :on-remove="businessLicenseUpdateRemove" accept=".jpg,.png,.jpeg">
                      <i class="el-icon-plus"></i>
                      <div slot="tip" class="el-upload__tip">
                        请上传格式为jpg/png的图片<br />且大小不超过500kb
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :span="24">
                  <el-form-item label="企业logo" prop="companyUrl">
                    <el-upload v-model="formLabelAlign.companyUrl" :action="headerUrl + '/common/upload'"
                      list-type="picture-card" :limit="2" :file-list="logoFileList"
                      :before-upload="handlePictureCardPreview" :on-success="logoSuccess" :on-remove="logoRemoves"
                      accept=".jpg,.png,.jpeg">
                      <i class="el-icon-plus"></i>
                      <div slot="tip" class="el-upload__tip">
                        请上传格式为png格式的透明底logo图片<br />且大小不超过500kb<br /><span
                          style="color: #999999">注：若无企业Logo可用企业形象照代替</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </el-col>
          </el-row>
        </el-card>
        <!--企业基本信息 end-->
        <!--产品信息 start-->
        <el-card class="box-card" shadow="never" v-if="stepNumber == 2">
          <div slot="header" class="clearfix">
            <span class="tip-tap">
              <h3><em>*</em>产品信息</h3>
              <!-- <a>（请您认真填写企业产品信息）</a> -->
            </span>
            <div style="float: right; width: 130px">
              <el-button type="primary" @click="addFormPurchase" size="small">新增</el-button>
            </div>
          </div>
          <!-- :row-key="Math.random()" -->
          <el-table :data="formLabelAlign.shopSoftwareList" style="width: 100%" @row-dblclick="rowPurchaseDblclick">
            <el-table-column label="产品名称" width="180">
              <template slot-scope="scope">
                <el-form-item label-width="auto" :prop="`shopSoftwareList[${scope.$index}].softwareName`"
                  :rules="{ required: true, message: '软件名称必填' }">
                  <el-tooltip placement="top" :content="scope.row.softwareName" :disabled="!scope.row.softwareName">
                    <span class="text-over" :style="{ color: scope.row.softwareName ? '' : '#a6a6a6' }">{{
                      scope.row.softwareName || "双击编辑软件信息" }}</span>
                  </el-tooltip>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="案例图片" min-width="200">
              <template slot-scope="scope">
                <div class="img-class">
                  <el-image fit="fit" v-for="item in splitList(scope.row.softwareUrl)" :src="item" style="width: 80px; height: 80px"></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品服务行业" width="180">
              <template slot-scope="scope">
                <el-tag style="margin-right: 5px;" v-for="item in splitList(scope.row.industry)">{{ item }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="产品服务环节" width="180">
              <template slot-scope="scope">
                <el-tag style="margin-right: 5px;" v-for="item in splitList(scope.row.serviceProcess)">{{ item
                  }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="产品安装包链接" width="180">
              <template slot-scope="scope">
                <el-tooltip placement="top" :content="scope.row.installationMethod"
                  :disabled="!scope.row.installationMethod">
                  <span class="text-over">{{ scope.row.installationMethod }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="是否免费" width="80">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.isfree == 0">免费</el-tag>
                <el-tag v-else type="success">收费</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="是否免费推广" width="180">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.isFreePromotion == 0">免费</el-tag>
                <el-tag v-else type="success">收费</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="130">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="small" circle
                  @click="editFormPurchase(scope.$index, scope.row)"></el-button>
                <el-button type="danger" icon="el-icon-delete" circle size="small"
                  @click="delRow(scope.$index, 'shopSoftwareList')"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <!--软件池 end-->

        <!--应用场景 start-->
        <el-card class="box-card" shadow="never" v-if="stepNumber == 3">
          <div slot="header" class="clearfix">
            <span class="tip-tap">
              <h3><em>*</em>应用场景案例信息</h3>
              <!-- <a>（至少填写一条面向中小企业应用案例信息）</a> -->
            </span>
            <div style="float: right; width: 130px">
              <el-button type="primary" @click="addFormProduct" size="small">新增</el-button>
            </div>
          </div>
          <!-- :row-key="Math.random()" -->
          <el-table :data="formLabelAlign.shopSceneList" style="width: 100%" @row-dblclick="rowDblclick">
            <el-table-column label="案例名称" width="180">
              <template slot-scope="scope">
                <el-tooltip placement="top" :content="scope.row.sceneName" :disabled="!scope.row.sceneName">
                  <span class="text-over" :style="{ color: scope.row.sceneName ? '' : '#a6a6a6' }">{{
                    scope.row.sceneName ||
                    "双击编辑产品" + (scope.$index + 1)
                  }}</span>
                </el-tooltip>
                <!--                </el-form-item>-->
              </template>
            </el-table-column>
            <el-table-column label="案例图片" min-width="200">
              <template slot-scope="scope">
                <div class="img-class">
                  <el-image fit="fit" v-for="item in splitList(scope.row.sceneUrl)" :src="item" style="width: 80px; height: 80px"></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="案例使用时间" min-width="200" prop="usageTime">
            </el-table-column>
            <el-table-column label="案例使用单位" min-width="200" prop="usageUnity">
            </el-table-column>
            <el-table-column label="案例所属省份" width="120">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.affiliationProvince == 0">山东省内</el-tag>
                <el-tag v-else type="success">山东省外</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="案例所属区域" width="180" prop="affiliation">
            </el-table-column>
            <el-table-column label="产品服务行业" width="180">
              <template slot-scope="scope">
                <el-tag style="margin-right: 5px;" v-for="item in splitList(scope.row.industry)">{{ item }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="产品服务环节" width="180">
              <template slot-scope="scope">
                <el-tag style="margin-right: 5px;" v-for="item in splitList(scope.row.serviceProcess)">{{ item
                  }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="130">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="small" circle
                  @click="editFormProduct(scope.$index, scope.row)"></el-button>
                <el-button type="danger" icon="el-icon-delete" circle size="small"
                  @click="delRow(scope.$index, 'shopSceneList')"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <!--应用场景 end-->
      </el-col>
      <el-col :span="5">
        <el-form-item></el-form-item>
      </el-col>
    </el-form>
    <!--提交表单按钮-->
    <el-col :span="24" style="text-align: center; margin: 20px 0 40px">
      <el-button type="primary" @click="previousPage" plain v-if="stepNumber > 1">上一页</el-button>
      <el-button type="primary" @click="submitForm" :loading="butLoading" v-if="stepNumber == 3">提交</el-button>
      <el-button type="primary" @click="nextPage" plain v-if="stepNumber < 3">下一页</el-button>
      <p style="color: #aaaaaa">
        提交前，请仔细检查所填内容是否完整。如出现显示异常，请刷新页面或更换浏览器进行填写。
      </p>
    </el-col>
    <!--新增产品弹窗-->
    <edit-dialog :dialog-visible="editVisible" :dialog-content="editContent" @visibleUpdate="visibleUpdate"
      @ok="handEditContent"></edit-dialog>
    <!--新增产品弹窗-->
    <edit-dialog-two :dialog-visible="editVisibleTwo" :dialog-content="editContent" @visibleUpdate="visibleUpdate"
      @ok="handEditContentTwo"></edit-dialog-two>
  </el-row>
</template>

<script>
import EditDialog from "./components/dialog/editDialog.vue";
import axios from "axios";
import EditDialogTwo from "@/views/formInfo/components/dialog/editDialogTwo.vue";
import citys from '../../utils/citys' // 引入城市数据
export default {
  name: "formIndex",
  components: {
    EditDialogTwo,
    EditDialog,
  },
  data() {
    return {
      stepNumber: 1,
      serviceTradeList: [],
      butLoading: false,
      rules: {
        companyName: [{ required: true, message: "服务商名称必填" }],
        contactName: [{ required: true, message: "企业联系人必填" }],
        // licenceNum: [{ required: true, message: "统一社会信用代码必填" }],
        // province: [{ required: true, message: "地址必选" }],
        affiliation: [{ required: true, message: "信息填报所属区县必选" }],
        contactTel: [
          { required: true, message: "联系人手机号必填" },
          { pattern: /^1[3-9]\d{9}$/, message: "手机号输入格式有误" },
        ],
        // area: [{ required: true, message: "地址必填" }],
        email: [
          { required: true, message: "邮箱必填" },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "邮箱输入格式有误",
          },
        ],
        duties: [{ required: true, message: "职务必填" }],
        companyAddress: [{ required: true, message: "详细地址必填" }],
        companyInfo: [{ required: true, message: "企业简介必填" }],
        industry: [{ required: true, message: "服务行业必选" }],
        // licenceUrl: [{ required: true, message: "请上传营业执照" }],
        //companyUrl: [{ required: true, message: "请上传企业logo" }],
      }, // 表单校验项
      // headerUrl: "http://47.92.64.122/biaodan/api", // 请求后台地址
      // headerUrl: "http://192.168.110.130:8080", // 请求后台地址
      labelPosition: "right", // 统一设置表单标签位置
      selectedOptions: [], // 选择的地区
      affiliationAdress: [],
      industryList: [],
      formLabelAlign: {
        affiliation: "",
        companyName: "", // 企业名称
        // province: "", //省
        // city: "", //市
        // area: "", // 区
        // licenceNum: "", // 企业官网
        companyAddress: "", // 详细地址
        companyInfo: "", // 企业简介
        contactName: "", // 企业联系人
        contactTel: "", // 联系人手机号
        email: "", // 邮箱
        duties: "", //职务
        industry: "", // 服务行业
        // companyUrl: "", // logo
        // licenceUrl: "", // 营业执照
        shopSceneList: [], // 应用案例信息
        shopSoftwareList: [], // 产品信息
      },
      logoFileList: [],
      businessLicenseList: [],
      enterpriseList: [],
      editingRow: -1, // 正在编辑的行索引
      typeList: ["", "success", "warning", "danger"],
      dialogVisible: false, // 弹窗开关
      editVisible: false, //
      editVisibleTwo: false, //
      dialogContent: {}, // 弹窗参数
      editContent: {}, // 弹窗参数
      hyList: [],
      optionsCitys: citys,
      weifangData: [
        {
          value: "潍坊市",
          label: "潍坊市",
          children: [
            { value: "潍城区", label: "潍城区" },
            { value: "奎文区", label: "奎文区" },
            { value: "坊子区", label: "坊子区" },
            { value: "寒亭区", label: "寒亭区" },
            { value: "临朐县", label: "临朐县" },
            { value: "昌乐县", label: "昌乐县" },
            { value: "昌邑市", label: "昌邑市" },
            { value: "青州市", label: "青州市" },
            { value: "诸城市", label: "诸城市" },
            { value: "寿光市", label: "寿光市" },
            { value: "安丘市", label: "安丘市" },
            { value: "高密市", label: "高密市" },
            { value: "高新区", label: "高新区" },
            { value: "滨海区", label: "滨海区" },
            { value: "峡山区", label: "峡山区" },
            { value: "经开区", label: "经开区" },
            { value: "保税区", label: "保税区" },
          ],
        },
        {
          value: "潍坊市外",
          label: "潍坊市外",
        },
      ],
      isMobile: false,
    };
  },
  mounted() {
    this.getDict();
    this.checkIfMobile();
    if (localStorage.getItem('information')) {
      this.$confirm('检测到有数据, 请问是否恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formLabelAlign = JSON.parse(localStorage.getItem('information'))
        this.industryList = this.formLabelAlign.industry.split(','); // ['Vue', '3', 'Array', 'String']
        this.affiliationAdress = this.formLabelAlign.affiliation.split(','); // ['Vue', '3', 'Array', 'String']
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  },
  methods: {
    // 判断是否为移动端
    checkIfMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      console.log(this.isMobile, "11111111111")
    },
    // 字符串转数组
    splitList(list) {
      return list.split(',');
    },
    // 获取字典信息
    getDict() {
      let params = {
        dictName: "company_industry,service_process"
      }
      axios
        .get("/api/getDictData", {
          params: params
        }, {
          timeout: 30000,
        })
        .then((res) => {
          if (res.data.code === 200) {
            // localStorage.setItem('submitTime', new Date())
            this.serviceTradeList = res.data.data.company_industry;
            // this.serviceProcessList = res.data.data.service_process;
          } else {
            // this.$message({ type: "error", message: res.data.msg });
          }
        })
        .finally(() => {
          // this.butLoading = false;
        });
    },
    /**省市区三级联动 */
    affiliationChooseAdd(value) {
      // this.form.address = value[0] + "/" + value[1] + "/" + value[2];
      this.formLabelAlign.affiliation = this.affiliationAdress.join(','); // Vue 3 Array String

    },
    /**省市区三级联动 */
    addressChoose(value) {
      // this.form.address = value[0] + "/" + value[1] + "/" + value[2];
      const labels = value.map(v => {
        const found = this.findLabelByValue(this.optionsCitys, v);
        return found ? found.label : v;
      });
      this.formLabelAlign.province = labels[0];
      this.formLabelAlign.city = labels[1];
      this.formLabelAlign.area = labels[2];
    },
    findLabelByValue(options, value) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          return options[i];
        }
        if (options[i].children) {
          const found = this.findLabelByValue(options[i].children, value);
          if (found) return found;
        }
      }
      return null;
    },
    // 删除行
    delRow(index, name) {
      this.formLabelAlign[name].splice(index, 1);
      this.formLabelAlign[name].forEach((item, index) => {
        item.index = index;
      });
    },
    // 关闭弹窗更新开关
    visibleUpdate(val) {
      this.dialogVisible = val;
      this.editVisible = val;
      this.editVisibleTwo = val;
    },
    selectIndustry() {
      console.log(this.industryList)
      this.formLabelAlign.industry = this.industryList.join(','); // Vue 3 Array String
    },
    // 下一页
    nextPage() {
      if (this.stepNumber == 1) {
        this.$refs.ruleform.validate((err, obj) => {
          if (!err) {
            // this.butLoading = false;
            this.$message({
              type: "error",
              message: obj[Object.keys(obj)[0]][0].message,
            });
          } else {
            this.stepNumber++;
            localStorage.setItem('information', JSON.stringify(this.formLabelAlign))
          }
        });
        return;
      }
      this.stepNumber++;
      localStorage.setItem('information', JSON.stringify(this.formLabelAlign))

    },
    // 上一页
    previousPage() {
      this.stepNumber--;
      localStorage.setItem('information', JSON.stringify(this.formLabelAlign))
    },
    // 提交企业信息表单
    submitForm() {
      // this.$refs.ruleform.resetFields();
      localStorage.setItem('information', JSON.stringify(this.formLabelAlign))
      // localStorage.clear();
      if (localStorage.getItem('submitTime')) {
        if ((new Date() - new Date(localStorage.getItem('submitTime'))) < 300000) {
          this.$message({
            type: "error",
            message: '您已提交信息，请勿重复提交！',
          })
          return
        }
      }
      this.butLoading = true;
      this.$refs.ruleform.validate((err, obj) => {
        if (!err) {
          this.$message({
            type: "error",
            message: obj[Object.keys(obj)[0]][0].message,
          });
          this.butLoading = false;
          return;
        }
        // if (
        //   this.formLabelAlign.shopSceneList.filter((item) => {
        //     return item.sceneName !== "";
        //   }).length < 1
        // ) {
        //   this.$message({
        //     type: "error",
        //     message: "至少填写一条场景信息",
        //   });
        //   this.butLoading = false;
        //   return;
        // }
        // if (
        //   this.formLabelAlign.shopSoftwareList.length < 1
        // ) {
        //   this.$message({
        //     type: "error",
        //     message: "至少填写一条产品信息",
        //   });
        //   this.butLoading = false;
        //   return;
        // }
        const params = {
          ...this.formLabelAlign,
        };
        params.shopSceneList = this.formLabelAlign.shopSceneList
          .filter((item) => {
            return item.sceneName !== "";
          })
          .map((item) => {
            return {
              ...item,
              sceneUrl: item.sceneUrl,
            };
          });
        axios
          .post("/api/companyImport", params, {
            timeout: 30000,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // location.reload();
              // localStorage.clear();
              localStorage.clear();
              this.$refs.ruleform.resetFields();
              // this.stepNumber = 1;
              localStorage.setItem('submitTime', new Date())
              
              this.$confirm("填写完成，您提交的信息已被平台收录", "提交成功", {
                cancelButtonText: "关闭",
                showConfirmButton: false,
                type: "success",
                center: true,
              }).then(() => {
                setTimeout(() => {
                  location.reload();
                  // this.stepNumber = 1;
                }, 1000)
              }).catch(() => {
                setTimeout(() => {
                  location.reload();
                  // this.stepNumber = 1;
                }, 1000)
              });
            } else {
              this.$message({ type: "error", message: res.data.msg });
            }
          })
          .finally(() => {
            this.butLoading = false;
          });
      });
    },
    // 上传图片做校验
    handlePictureCardPreview(file) {
      const isJPG = ["image/jpeg", "image/jpg", "image/png"].includes(
        file.type
      );
      if (!isJPG) {
        this.$message.error("上传文件只能是 图片 格式!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传企业logo回调
    logoSuccess(res, file, fileList) {
      this.formLabelAlign.companyUrl = res.url;
      this.logoFileList = [fileList[1]];
    },
    // 上传企业logo回调
    logoRemoves() {
      this.formLabelAlign.companyUrl = "";
      this.logoFileList = [];
    },
    // 上传营业执照回调
    businessLicenseUpdateSuccess(res, file, fileList) {
      this.formLabelAlign.licenceUrl = res.url;
      this.businessLicenseList = [fileList[1]];
    },
    // 上传营业执照回调
    businessLicenseUpdateRemove() {
      this.formLabelAlign.licenceUrl = "";
      this.businessLicenseList = [];
    },
    // 新增
    addFormProduct() {
      this.editContent = {
        sceneName: "",
        industry: "",
        serviceProcess: "",
        sceneInfo: "",
        solveProblem: "",
        effect: "",
        sceneUrl: "",
        affiliationProvince: "",
        usageUnity: "",
        affiliation: "",
        remark: "",
        type: "add",
      };
      this.editVisible = true;
    },
    // 新增
    addFormPurchase() {
      this.editContent = {
        softwareName: "",
        industry: "",
        serviceProcess: "",
        softwareInfo: "",
        isFree: "0",
        softwareUrl: "",
        chargeRules: "",
        installationMethod: "",
        isFreePromotion: "0",
        remark: "",
        experienceMode: "0",
        type: "add",
      };
      this.editVisibleTwo = true;
    },
    // 编辑
    editFormProduct(index, row) {
      this.editContent = row;
      this.editContent.index = index;
      this.editContent.type = "edit";
      this.editVisible = true;
    },
    // 编辑
    editFormPurchase(index, row) {
      this.editContent = row;
      this.editContent.index = index;
      this.editContent.type = "edit";
      this.editVisibleTwo = true;
    },
    rowDblclick(row) {
      this.editFormProduct(row.index, row);
    },
    rowPurchaseDblclick(row) {
      this.editFormPurchase(row.index, row);
    },
    // 产品信息弹窗回调
    handEditContent(data) {
      if (data.type === "add") {
        this.formLabelAlign.shopSceneList.push(data);
        this.formLabelAlign.shopSceneList.forEach((item, index) => {
          item.index = index;
        });
      } else {
        this.formLabelAlign.shopSceneList[data.index] = {
          ...data,
        };
        this.formLabelAlign.shopSceneList.push(
          {
            sceneName: "",
            industry: "",
            serviceProcess: "",
            sceneInfo: "",
            solveProblem: "",
            effect: "",
            sceneUrl: "",
            affiliationProvince: "",
            usageUnity: "",
            affiliation: "",
            remark: "",
            type: "add",
          });
        this.formLabelAlign.shopSceneList.splice(
          this.formLabelAlign.shopSceneList.length - 1,
          1
        );
      }
    },
    // 产品信息弹窗回调
    handEditContentTwo(data) {
      if (data.type === "add") {
        this.formLabelAlign.shopSoftwareList.push(data);
        this.formLabelAlign.shopSoftwareList.forEach((item, index) => {
          item.index = index;
        });
      } else {
        this.formLabelAlign.shopSoftwareList[data.index] = {
          ...data,
        };
        this.formLabelAlign.shopSoftwareList.push({
          softwareName: "",
          industry: "",
          serviceProcess: "",
          softwareInfo: "",
          isFree: "0",
          softwareUrl: "",
          chargeRules: "",
          installationMethod: "",
          isFreePromotion: "0",
          remark: "",
          experienceMode: "0",
        });
        this.formLabelAlign.shopSoftwareList.splice(
          this.formLabelAlign.shopSoftwareList.length - 1,
          1
        );
      }
    },
  },
};
</script>
<style scoped>
.box-card {
  margin-top: 20px;
}

em {
  font-style: inherit;
  color: #f56c6c;
}

.cell .el-button--text {
  padding-top: 0;
}

.el-tag {
  height: 46px;
}

.el-tag .xuanzhong {
  color: chocolate;
}

.el-col-4 {
  text-align: center;
}

.img-class {
  border: 0;
  display: inline-block;
  margin-left: 5px;
}

.tip-tap h3 {
  margin: 0;
  display: inline-block;
  font-size: 16px;
}

.tip-tap a {
  font-size: 14px;
  color: rgba(51, 51, 51, 0.64);
  display: inline-block;
}

.el-checkbox:first-child {
  margin-left: 10px;
}

.el-checkbox {
  margin-bottom: 10px;
}

:deep .el-textarea__inner {
  font: 400 13.3333px Arial;
}

.title-small {
  font-size: 12px;
  color: #a6a6a6;
}

:deep .el-upload--picture-card {
  height: 120px;
  width: 120px;
  line-height: 120px;
}

:deep .el-upload-list--picture-card .el-upload-list__item {
  height: 120px;
  width: 120px;
  line-height: 120px;
}

:deep .el-upload-list__item-status-label {
  line-height: 24px;
}

.text-over {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
}
</style>