<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from "@/views/formInfo/index.vue";
export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
.text_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.flex_center{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
