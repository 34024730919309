<template>
  <el-dialog width="80%" :visible="dialogVisible" :title="dialogContent.type === 'edit' ? '编辑产品' : '新增产品'"
    @close="closeDialog" @opened="openDialog">
    <el-form :model="ruleForm" :rules="rules" ref="ruleFormDialogs" label-width="130px" class="demo-ruleForm">
      <el-form-item label="产品名称" prop="softwareName">
        <el-input v-model="ruleForm.softwareName" size="small" placeholder="输入产品名称"></el-input>
      </el-form-item>
      <el-form-item label="产品图片" prop="softwareUrl">
        <el-upload :action="'/common/upload'" :show-file-list="false" :before-upload="handleProductLogoPreview"
          :on-success="productLogoSuccess" accept=".jpg,.png,.jpeg">
          <el-button size="small" type="primary">点击上传</el-button>
          <!-- <i class="el-icon-plus"></i> -->
          <div slot="tip" class="el-upload__tip">
            请上传格式为jpg/png的图片,且每张图片大小不超过2MB,单张上传，最多可以上传三张。
          </div>
        </el-upload>
        <div class="upload_pic_img_list">
          <div class="upload_pic_img" v-for="(item, index) in productLogo" :key="index">
            <div class="close_img flex_center" @click="closeUploadImg(index)">删除</div>
            <img :src="item" alt="">
          </div>
        </div>

      </el-form-item>

      <el-form-item label="服务行业" prop="industry">
        <el-select v-model="industryListTwo" placeholder="请选择服务行业" multiple filterable allow-create default-first-option
          @change="selectIndustryTwo">
          <el-option v-for="item in serviceTradeList" :key="item.dictValue" :label="item.dictLabel"
            :value="item.dictLabel">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务环节" prop="serviceProcess">
        <el-select v-model="industryListThree" placeholder="请选择服务环节" multiple collapse-tags
          @change="selectIndustryThree">
          <el-option v-for="item in serviceProcessList" :key="item.dictValue" :label="item.dictLabel"
            :value="item.dictLabel">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品描述" prop="softwareInfo">
        <editor v-model="ruleForm.softwareInfo" editorId="softwareInfo" />
      </el-form-item>
      <el-form-item label="产品体验方式" prop="experienceMode">
        <el-select v-model="ruleForm.experienceMode" placeholder="请选择是否免费">
          <el-option label="上传产品安装压缩包" value="1">
          </el-option>
          <el-option label="自有链接上传" value="0">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品安装包链接" prop="installationMethod" v-if="ruleForm.experienceMode == 0">
        <el-input v-model="ruleForm.installationMethod" size="small" placeholder="输入产品安装包链接"></el-input>
      </el-form-item>
      <el-form-item label="产品压缩包" prop="softwareUrl" v-if="ruleForm.experienceMode == 1">
        <el-upload :limit="1" :action="'/common/upload'" :show-file-list="false" :before-upload="beforeUploadZip"
          :on-success="uploadSuccessZip" :on-remove="removeUploadZip">
          <el-button size="small" type="primary">点击上传</el-button>
          <!-- <i class="el-icon-plus"></i> -->
          <div style="width: 100px" class="text_ellipsis">{{ ruleForm.installationMethod }}</div>
          <div slot="tip" class="el-upload__tip">
            请上传压缩包,大小不超过100MB
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="是否免费" prop="isFree">
        <el-select v-model="ruleForm.isFree" placeholder="请选择是否免费">
          <el-option label="否" value="1">
          </el-option>
          <el-option label="是" value="0">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否免费推广" prop="isFreePromotion">
        <el-select v-model="ruleForm.isFreePromotion" placeholder="请选择是否免费推广">
          <el-option label="否" value="1">
          </el-option>
          <el-option label="是" value="0">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收费规则" prop="chargeRules" v-if="ruleForm.isFree == 1">
        <editor v-model="ruleForm.chargeRules" editorId="chargeRules" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" :rows="2" placeholder="输入备注" v-model="ruleForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" size="small">取 消</el-button>
      <el-button type="primary" @click="handleOk" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
import Editor from "../Editor/Editor.vue";
export default {
  components: {
    Editor
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      industryListTwo: [],
      industryListThree: [],
      serviceTradeList: [],
      serviceProcessList: [],
      ruleForm: {
        softwareName: "",
        industry: "",
        serviceProcess: "",
        softwareInfo: "",
        isFree: "0",
        softwareUrl: "",
        chargeRules: "",
        installationMethod: "",
        isFreePromotion: "0",
        remark: "",
        experienceMode: "0"
      },
      productLogo: [],
      rules: {
        softwareName: [{ required: true, message: "软件名称必填" }],
        industry: [{ required: true, message: "服务行业必选" }],
        serviceProcess: [{ required: true, message: "服务环节必选" }],
        softwareInfo: [{ required: true, message: "产品描述必填" }],
        softwareUrl: [{ required: true, message: "宣传图必传" }],
        isFree: [{ required: true, message: "是否免费必选" }],
        //chargeRules: [{ required: true, message: "收费规则必填" }],
        installationMethod: [{ required: true, message: "安装方式必填" }],
        isFreePromotion: [{ required: true, message: "是否免费推广必选" }],
        // remark: [{ required: true, message: "备注必填" }],
      },
      // headerUrl: "http://47.92.64.122/biaodan/api", // 请求后台地址
      headerUrl: "http://192.168.110.130:8080", // 请求后台地址
    };
  },
  mounted() {
    this.getDict();
  },
  methods: {
    // 获取字典信息
    getDict() {
      let params = {
        dictName: "company_industry,service_process"
      }
      axios
        .get("/api/getDictData", {
          params: params
        }, {
          timeout: 30000,
        })
        .then((res) => {
          if (res.data.code === 200) {
            // localStorage.setItem('submitTime', new Date())
            this.serviceTradeList = res.data.data.company_industry;
            this.serviceProcessList = res.data.data.service_process;
          } else {
            // this.$message({ type: "error", message: res.data.msg });
          }
        })
        .finally(() => {
          // this.butLoading = false;
        });
    },
    selectIndustryTwo() {
      this.ruleForm.industry = this.industryListTwo.join(','); // Vue 3 Array String
    },
    selectIndustryThree() {
      this.ruleForm.serviceProcess = this.industryListThree.join(','); // Vue 3 Array String
    },
    // 关闭弹窗回调
    closeDialog() {
      this.$emit("visibleUpdate", false);
      this.ruleForm.productLogoList = [];
      this.$refs.ruleFormDialogs.resetFields();
    },
    // 打开弹窗请求接口获取行业包含标签
    openDialog() {
      this.ruleForm = JSON.parse(JSON.stringify(this.dialogContent));
      if (this.ruleForm.industry != "") {
        this.industryListTwo = this.ruleForm.industry.split(','); // ['Vue', '3', 'Array', 'String']
      }
      if (this.ruleForm.serviceProcess != "") {
        this.industryListThree = this.ruleForm.serviceProcess.split(','); // ['Vue', '3', 'Array', 'String']
      }
      if (this.ruleForm.softwareUrl != "") {
        this.productLogo = this.ruleForm.softwareUrl.split(','); // ['Vue', '3', 'Array', 'String']
      }
    },
    // 确定回调
    handleOk() {
      this.$refs.ruleFormDialogs.validate((obj) => {
        if (!obj) {
          return;
        }
        this.$emit("ok", JSON.parse(JSON.stringify(this.ruleForm)));
        this.closeDialog();
      });
    },
    // 上传图片做校验
    handleProductLogoPreview(file) {
      if (this.productLogo.length >= 3) {
        this.$message.error("最多上传3张图片");
        return false;
      }
      const isJPG = ["image/jpeg", "image/jpg", "image/png"].includes(
        file.type
      );
      if (!isJPG) {
        this.$message.error("上传文件只能是 图片 格式!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    productLogoSuccess(res, file, fileList) {
      // this.ruleForm.softwareUrl = res.fileName;
      // this.productLogo = fileList.map((item) => {
      //   return item.response.url;
      // });
      this.productLogo.push(res.fileName);
      this.ruleForm.softwareUrl = this.productLogo.join(',');
    },

    // 删除封面图
    closeUploadImg(index) {
      this.productLogo.splice(index, 1);
    },

    beforeUploadZip(file) {
      console.log(file.type, "11111111")
      const isJPG = ["zip", "application/x-zip-compressed"].includes(
        file.type
      );
      if (!isJPG) {
        this.$message.error("上传文件只能是 ZIP 格式!");
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 100MB!");
      }
      return isJPG && isLt2M;
    },
    uploadSuccessZip(res, file, fileList) {
      this.ruleForm.installationMethod = res.fileName;
    },
    removeUploadZip() {
      this.ruleForm.installationMethod = "";
    },
  },
};
</script>
<style scoped>
:deep .el-dialog__body {
  padding: 0 20px;
}

.upload_pic_img_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.upload_pic_img {
  width: 118px;
  height: 118px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 10px;
  background-color: #f4f4f4;
}

.upload_pic_img img {
  width: 118px;
  height: 118px;
  border-radius: 10px;
  object-fit: contain;
}

.close_img {
  width: 100%;
  height: 30px;
  background-color: #B5B5B5;
  color: #FFFFFF;
  opacity: 0.8;
  font-size: 22px;
  border-radius: 10px 10px 0 0;
  margin-bottom: -30px;
}
</style>
