<template>
  <el-dialog width="80%" :visible="dialogVisible" :title="dialogContent.type === 'edit' ? '编辑应用场景' : '新增应用场景'"
    @close="closeDialog" @opened="openDialog">
    <el-form :model="ruleForm" :rules="rules" ref="ruleFormDialog" label-width="110px" class="demo-ruleForm">
      <el-form-item label="案例名称" prop="sceneName">
        <el-input v-model="ruleForm.sceneName" size="small" placeholder="输入案例名称"></el-input>
      </el-form-item>
      <el-form-item label="案例图片" prop="sceneUrl">
        <el-upload :action="'/common/upload'" :show-file-list="false"
          :before-upload="handleProductLogoPreview" :on-success="productLogoSuccess" accept=".jpg,.png,.jpeg">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            请上传格式为jpg/png的图片,且每张图片大小不超过2MB,单张上传，最多可以上传三张。
          </div>
        </el-upload>
        <div class="upload_pic_img_list">
          <div class="upload_pic_img" v-for="(item, index) in productLogo" :key="index">
            <div class="close_img flex_center" @click="closeUploadImg(index)">删除</div>
            <img :src="item" alt="">
          </div>
        </div>
      </el-form-item>
      <el-form-item label="使用时间" prop="usageTime">
        <el-date-picker v-model="ruleForm.usageTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
          style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="使用单位" prop="usageUnity">
        <el-input v-model="ruleForm.usageUnity" size="small" placeholder="输入使用单位"></el-input>
      </el-form-item>
      <el-form-item label="所属省份" prop="affiliationProvince">
        <el-select v-model="ruleForm.affiliationProvince" placeholder="请选择所属省份" @change="selectAffProcince">
          <el-option label="山东省外" value="1">
          </el-option>
          <el-option label="山东省内" value="0">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属区域" prop="affiliation">
        <el-cascader v-model="affiliationAdd" :options="optionsCitys" :props="{ expandTrigger: 'hover' }"
          style="width: 100%" @change="affiliationChoose"></el-cascader>
      </el-form-item>
      <el-form-item label="所属行业" prop="industry">
        <el-select v-model="industryListTwo" placeholder="请选择服务行业" multiple filterable allow-create default-first-option
          @change="selectIndustryTwo">
          <el-option v-for="item in serviceTradeList" :key="item.dictValue" :label="item.dictLabel"
            :value="item.dictLabel">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务环节" prop="serviceProcess">
        <el-select v-model="industryListThree" placeholder="请选择服务环节" multiple collapse-tags
          @change="selectIndustryThree">
          <el-option v-for="item in serviceProcessList" :key="item.dictValue" :label="item.dictLabel"
            :value="item.dictLabel">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="案例概述" prop="sceneInfo">
        <editor v-model="ruleForm.sceneInfo" editorId="sceneInfo" />
      </el-form-item>
      <el-form-item label="解决行业痛点" prop="solveProblem">
        <editor v-model="ruleForm.solveProblem" editorId="solveProblem" />
      </el-form-item>
      <el-form-item label="使用成效" prop="effect">
        <editor v-model="ruleForm.effect" editorId="effect" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" :rows="2" placeholder="输入备注" v-model="ruleForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" size="small">取 消</el-button>
      <el-button type="primary" @click="handleOk" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios";
import Editor from "../Editor/Editor.vue";
import citys from '../../../../utils/citys' // 引入城市数据
export default {
  components: {
    Editor
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      industryListTwo: [],
      industryListThree: [],
      serviceTradeList: [],
      serviceProcessList: [],
      ruleForm: {
        sceneName: "",
        industry: "",
        serviceProcess: "",
        sceneInfo: "",
        solveProblem: "",
        effect: "",
        sceneUrl: "",
        affiliationProvince: "",
        usageUnity: "",
        affiliation: "",
        remark: "",
      },
      affiliationAdd: [],
      productLogo: [],
      rules: {
        sceneName: [{ required: true, message: "场景名称必填" }],
        sceneInfo: [{ required: true, message: "产品描述必填" }],
        sceneUrl: [{ required: true, message: "请上传产品图片" }],
        // remark: [{ required: true, message: "备注必填" }],
        industry: [{ required: true, message: "服务行业必选" }],
        serviceProcess: [{ required: true, message: "服务环节必选" }],
        affiliationProvince: [{ required: true, message: "所属省份必选" }],
        usageTime: [{ required: true, message: "使用时间必填" }],
        usageUnity: [{ required: true, message: "使用单位必填" }],
        solveProblem: [{ required: true, message: "解决问题必填" }],
        effect: [{ required: true, message: "取得成效必填" }],
        affiliation: [{ required: true, message: "所属区县必选" }],
      },
      allCitys: citys,
      optionsCitys: citys,
      weifangData: [
        {
          value: "山东省",
          label: "山东省",
          children: [
            {
              value: "潍坊市",
              label: "潍坊市",
              children: [
                { value: "潍城区", label: "潍城区" },
                { value: "奎文区", label: "奎文区" },
                { value: "坊子区", label: "坊子区" },
                { value: "寒亭区", label: "寒亭区" },
                { value: "临朐县", label: "临朐县" },
                { value: "昌乐县", label: "昌乐县" },
                { value: "昌邑市", label: "昌邑市" },
                { value: "青州市", label: "青州市" },
                { value: "诸城市", label: "诸城市" },
                { value: "寿光市", label: "寿光市" },
                { value: "安丘市", label: "安丘市" },
                { value: "高密市", label: "高密市" },
                { value: "高新区", label: "高新区" },
                { value: "滨海区", label: "滨海区" },
                { value: "峡山区", label: "峡山区" },
                { value: "经开区", label: "经开区" },
                { value: "保税区", label: "保税区" },
              ],
            },
          ],
        }
      ],
      // headerUrl: "http://47.92.64.122/biaodan/api", // 请求后台地址
      headerUrl: "http://bd.sdxhsn.com/", // 请求后台地址
    };
  },
  mounted() {
    this.getDict();
  },
  methods: {
    // 获取字典信息
    getDict() {
      let params = {
        dictName: "company_industry,service_process"
      }
      axios
        .get("/api/getDictData", {
          params: params
        }, {
          timeout: 30000,
        })
        .then((res) => {
          if (res.data.code === 200) {
            // localStorage.setItem('submitTime', new Date())
            this.serviceTradeList = res.data.data.company_industry;
            this.serviceProcessList = res.data.data.service_process;
            console.log(this.serviceTradeList, "111111111111111111")
          } else {
            // this.$message({ type: "error", message: res.data.msg });
          }
        })
        .finally(() => {
          // this.butLoading = false;
        });
    },
    // 字符串转数组
    splitList(list) {
      return list.split(',');
    },
    selectIndustryTwo() {
      this.ruleForm.industry = this.industryListTwo.join(','); // Vue 3 Array String
    },
    selectIndustryThree() {
      this.ruleForm.serviceProcess = this.industryListThree.join(','); // Vue 3 Array String
    },
    selectAffProcince(val) {
      if (val == 1) {
        this.optionsCitys = this.allCitys;
      } else {
        this.optionsCitys = [];
        this.allCitys.forEach((item) => {
          if (item.value == "370000") {
            this.optionsCitys.push(item);
          }
          // 
        })
      }

    },
    /**省市区三级联动 */
    affiliationChoose(value) {
      console.log("##选中的地区", value);
      // this.form.address = value[0] + "/" + value[1] + "/" + value[2];
      // this.ruleForm.affiliation = value.join(','); // Vue 3 Array String


      // console.log(this.ruleForm.affiliation, "11111111111111"); // 打印选中的汉字
      const labels = value.map(v => {
        const found = this.findLabelByValue(this.optionsCitys, v);
        return found ? found.label : v;
      });
      this.ruleForm.affiliation = labels.join(','); // Vue 3 Array String
      console.log(this.ruleForm.affiliation, "111111111111")

    },
    findLabelByValue(options, value) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          return options[i];
        }
        if (options[i].children) {
          const found = this.findLabelByValue(options[i].children, value);
          if (found) return found;
        }
      }
      return null;
    },
    // 关闭弹窗回调
    closeDialog() {
      this.$emit("visibleUpdate", false);
      this.ruleForm.sceneUrl = [];
      this.$refs.ruleFormDialog.resetFields();
    },
    // 打开弹窗请求接口获取行业包含标签
    openDialog() {
      this.ruleForm = JSON.parse(JSON.stringify(this.dialogContent));
      if (this.ruleForm.industry != "") {
        this.industryListTwo = this.ruleForm.industry.split(','); // ['Vue', '3', 'Array', 'String']
      }
      if (this.ruleForm.serviceProcess != "") {
        this.industryListThree = this.ruleForm.serviceProcess.split(','); // ['Vue', '3', 'Array', 'String']
      }
      if (this.ruleForm.sceneUrl != "") {
        this.productLogo = this.ruleForm.sceneUrl.split(',');
      }

      // 地区转换
      // this.affiliationAdd = this.ruleForm.affiliation.split(','); // ['Vue', '3', 'Array', 'String']
      // ['Vue', '3', 'Array', 'String']
      this.selectAffProcince(this.ruleForm.affiliationProvince);
      let valueList = this.ruleForm.affiliation.split(',');
      this.affiliationAdd = valueList.map(v => {
        const found = this.findLabelById(this.optionsCitys, v);
        return found ? found.value : v;
      });
      // this.findLabelById(this.optionsCitys);
    },
    findLabelById(options, label) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].label === label) {
          return options[i];
        }
        if (options[i].children) {
          const found = this.findLabelById(options[i].children, label);
          if (found) return found;
        }
      }
      return null;
    },
    // 确定回调
    handleOk() {
      this.$refs.ruleFormDialog.validate((obj) => {
        if (!obj) {
          return;
        }
        console.log(this.ruleForm, "1111111111")
        this.$emit("ok", JSON.parse(JSON.stringify(this.ruleForm)));
        this.closeDialog();
      });
    },
    // 上传图片做校验
    handleProductLogoPreview(file) {
      if (this.productLogo.length >= 3) {
        this.$message.error("最多上传3张图片");
        return false;
      }
      const isJPG = ["image/jpeg", "image/jpg", "image/png"].includes(
        file.type
      );
      if (!isJPG) {
        this.$message.error("上传文件只能是 图片 格式!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    productLogoSuccess(res, file, fileList) {
      // this.ruleForm.softwareUrl = res.fileName;
      // this.productLogo = fileList.map((item) => {
      //   return item.response.url;
      // });
      this.productLogo.push(res.fileName);
      this.ruleForm.sceneUrl = this.productLogo.join(',');
    },

    // 删除封面图
    closeUploadImg(index) {
      this.productLogo.splice(index, 1);
    },

  },
};
</script>
<style scoped>
:deep .el-dialog__body {
  padding: 0 20px;
}

.upload_pic_img_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.upload_pic_img {
  width: 118px;
  height: 118px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 10px;
  background-color: #f4f4f4;
}

.upload_pic_img img {
  width: 118px;
  height: 118px;
  border-radius: 10px;
  object-fit: contain;
}

.close_img {
  width: 100%;
  height: 30px;
  background-color: #B5B5B5;
  color: #FFFFFF;
  opacity: 0.8;
  font-size: 22px;
  border-radius: 10px 10px 0 0;
  margin-bottom: -30px;
}
</style>
